<template>
  <v-data-table
    class="qr-table"
    :headers="headers"
    :items="products"
    height="670.3px"
    hide-default-footer>
    <template v-slot:top>
      <div class="d-flex justify-space-between">
        <h2>Print QR Code</h2>
        <div>
          <v-btn
            class="mr-2"
            color="primary"
            @click="printA4()">
            <v-icon>mdi-printer</v-icon>
            Print A4
          </v-btn>
          <v-btn
            color="secondary"
            @click="printGodex()">
            <v-icon>mdi-printer</v-icon>
            Print Godex
          </v-btn>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <tr
        v-for="(product, index) in products"
        :key="`product-index-${index}`"
        height="50px">
        <td>{{ index + 1 }}</td>
        <td>{{ avoidNullValue(product, 'skuCode') }}</td>
        <td width="30%">
          {{ avoidNullValue(product, 'productName') }}
        </td>
        <td>{{ avoidNullValue(product, 'options') }}</td>
        <td>{{ avoidNullValue(product, 'gwCollection') }}</td>
        <td
          width="20%"
          class="px-2">
          <v-text-field
            v-model.number="product.amount"
            class="number-field"
            outlined
            dense
            min="0"
            hide-details
            prepend-inner-icon="mdi-minus"
            append-icon="mdi-plus"
            @click:prepend-inner="removeAmount(index)"
            @click:append="addAmount(index)" />
        </td>
        <td>
          <v-btn
            icon
            @click="removeSku(index)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { setPrintItem } from '@/assets/js/helper'

export default {
  props: {
    products: {
      type: Array,
      default: () => []
    },
    offset: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'No.',
          value: 'no',
          align: 'center',
          sortable: false
        },
        {
          text: 'SKU ID',
          value: 'skuId',
          align: 'center',
          sortable: false
        },
        {
          text: 'Product Name',
          value: 'productName',
          align: 'center',
          sortable: false,
          width: '30%'
        },
        {
          text: 'Options',
          value: 'options',
          align: 'center',
          sortable: false
        },
        {
          text: 'Collection',
          value: 'gwCollection',
          align: 'center',
          sortable: false
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'center',
          sortable: false,
          width: '20%'
        },
        {
          text: 'Remove',
          value: 'remove',
          align: 'center',
          sortable: false
        }
      ]
    }
  },
  methods: {
    addAmount (index) {
      this.products[index].amount++
    },
    removeAmount (index) {
      if (this.products[index].amount > 1) this.products[index].amount--
    },
    avoidNullValue (object, properties, defaultValue = '-') {
      return object[properties] || defaultValue
    },
    removeSku (index) {
      this.$emit('remove-by-index', index)
    },
    printA4 () {
      setPrintItem(this.products)
      // eslint-disable-next-line no-alert
      alert('กรุณาเลือกขนาดกระดาษเป็น A4\nรูปแบบ: แนวตั้ง\nระยะขอบกระดาษ: ค่าเริ่มต้น\nติ๊กส่วนหัวและส่วนท้ายของกระดาษออก')
      const routeData = this.$router.resolve({ name: 'QRcodeA4Page' })
      window.open(routeData.href, '_blank')
    },
    printGodex () {
      setPrintItem(this.products)
      // eslint-disable-next-line no-alert
      alert('กรุณาเลือกขนาดกระดาษเป็น 10x6\nรูปแบบ: แนวตั้ง\nระยะขอบกระดาษ: ปิด\nติ๊กส่วนหัวและส่วนท้ายของกระดาษออก')
      const routeData = this.$router.resolve({ name: 'QRcodeGodexPage' })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.qr-table td {
  text-align: center;
  margin: 12px;
}
</style>
